<template>
  <div class="products">
    <div class="productsInner">
      <div v-if="products.length < 1" style="margin: 25px; text-align: center;">
        <p>You have no products yet.</p>
      </div>
      <Product
        v-for='(product) in products'
        :key='product.key'
        :productName=product[6].value
        :productType=product[23].value
        :activityDuration=product[18].value
        :deliveryAddress=product[29].value
        :status=product[41].value
        :imgUrl=product[65].value
        :productId=product[3].value
        @child-data='viewPricing'
        @child-data-duplicate='viewPricingDuplicate'
      />
      <v-overlay
        :value="overlay"
      >
        <PricingTable
          :productTitle=productName
          :pricingData=pricingData
          :pricingDataArray=pricingDataArray
          @child-data='overlay = false'
          style="position:sticky;top:0;"
        />
      </v-overlay>
      <v-overlay
        :value="overlayDuplicate"
      >
        <PricingTableDuplicate
          :productId=productId
          :productTitle=productName
          :pricingData=pricingData
          :pricingDataArray=pricingDataArray
          @child-data-duplicate='overlayDuplicate = false'
          style="position:sticky;top:0;"
        />
      </v-overlay>
    </div>
    <div class="buttonContainer">
      <v-btn to="/account" x-large color="#4390ce" dark style="margin: 5px 0; width: 200px;">
        Account
      </v-btn>
      <v-btn to="/add-product" x-large color="#4390ce" dark style="margin: 5px 0; width: 200px;">
        Add New Product
      </v-btn>
    </div>
  </div>
</template>

<script>
import Product from '../components/Product.vue';
import PricingTable from '../components/PricingTable.vue';
import PricingTableDuplicate from '../components/PricingTableDuplicate.vue';

export default {
  name: 'Products',
  components: { Product, PricingTable, PricingTableDuplicate },
  data() {
    return {
      absolute: true,
      overlay: false,
      overlayDuplicate: false,
      pricingData: {},
      pricingDataArray: [],
      productName: '',
      productId: '',
    };
  },
  computed: {
    products() {
      return this.$store.state.supplierProducts;
    },
    token() {
      return this.$store.state.token;
    },
  },
  methods: {
    viewPricing(productId, productName) {
      fetch(`${process.env.VUE_APP_API_URL}/api/suppliers-old/get-pricing/${productId}`,
        { headers: { Authorization: `Bearer ${this.token}` } })
        .then(async (response) => {
          const data = await response.json();
          if (response.status === 200) {
            this.overlay = !this.overlay;
            // eslint-disable-next-line prefer-destructuring
            this.pricingData = data[0];
            this.pricingDataArray = data;
            this.productName = productName;
          } else {
            this.$store.commit('notify', 'Failed to load pricing. Please try again.');
          }
        })
        .catch((err) => console.log(err));
    },
    viewPricingDuplicate(productId, productName) {
      fetch(`${process.env.VUE_APP_API_URL}/api/suppliers-old/get-pricing/${productId}`,
        { headers: { Authorization: `Bearer ${this.token}` } })
        .then(async (response) => {
          const data = await response.json();
          if (response.status === 200) {
            this.productId = productId;
            this.overlayDuplicate = !this.overlayDuplicate;
            // eslint-disable-next-line prefer-destructuring
            this.pricingData = data[0];
            this.pricingDataArray = data;
            this.productName = productName;
          } else {
            this.$store.commit('notify', 'Failed to load pricing. Please try again.');
          }
        })
        .catch((err) => console.log(err));
    },
  },
  beforeCreate() {
    this.$nextTick(() => {
      this.$store.dispatch('getProducts');
    });
  },
};
</script>

<style lang="scss" scoped>
  .products {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .productsInner {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 1200px;
    width: 100%;
  }
  @media only screen and (max-width: 1100px) {
    .products {
      margin: 20px;
    }
  }
  .buttonContainer {
    display: flex;
    justify-content: space-between;
    max-width: 500px;
    width: 100%;
    margin: 10px 0 35px 0;
  }
  @media only screen and (max-width: 1100px) {
    .buttonContainer {
      flex-direction: column;
      align-items: center;
    }
  }
</style>
