<template>
<div style="flex-basis: 33.333333%; margin: 0 20px; min-width: 374px;">
  <v-card
    class="mx-auto my-12"
    max-width="374"
  >
    <template slot="progress">
      <v-progress-linear
        color="deep-purple"
        height="10"
        indeterminate
      ></v-progress-linear>
    </template>

    <div v-if="showUpdateProductImage">
      <v-img v-if="imgUrl" height="250" :src=imgUrl></v-img>
      <v-img v-else height="250" src='../assets/generic.png'></v-img>
      <v-file-input
        style='margin:25px 15px 15px 15px;'
        :rules="rules"
        v-model="file"
        accept="image/png, image/jpeg, image/bmp"
        placeholder="Update Product Image"
        label="Update Product Image"
      ></v-file-input>
    </div>

    <div v-else>
      <v-img v-if="imgUrl" height="250" :src=imgUrl></v-img>
      <v-img v-else height="250" src='../assets/generic.png'></v-img>

      <v-card-title
        style='min-height:128px;flex-direction:column;justify-content:center;align-items:flex-start'
      >
        <div style="font-size:12px;">Status: {{status}}</div>
        <div style="word-break:break-word">{{productName}}</div>
      </v-card-title>

      <v-card-text style="min-height:148px;">
        <b>Activity Duration: {{Number.parseFloat(activityDuration*2.77778e-7).toFixed(2)}} hours</b>
        <br>
        {{deliveryAddress}}
        <br><br>
        {{productTypeClean}}
      </v-card-text>
    </div>

    <v-divider class="mx-4"></v-divider>

    <v-overlay
      :value="overlay"
      opacity="0.9"
      z-index="99"
    >
      <div style="width: 500px; height: 400px; background-color: #fff; color: #000; padding: 10px;">
        <v-row>
          <v-spacer></v-spacer>
          <v-btn color="#283c86" text @click="overlay = false">X</v-btn>
        </v-row>
        <br>
        <div style="display: flex; flex-direction: column; align-items: center; width: 98%;">
          <div style="margin-left: 30px;">
          <h2>Add New Pricing</h2>
          <br>
          <v-btn color="#40832F" @click="addNewPricing">
            Add a price with a blank form
          </v-btn>
          <br><br>
          <p>
            In an effort to save your valuable time, if you have existing pricing for this product,
            you can copy the existing pricing. This will create the new price by auto filling in the
            old information and just allowing you to edit what you need to and save. Choose an existing
            price here:
          </p>
          <v-btn color="#40832F" @click="emitViewPricingDuplicate">
            Copy an existing price
          </v-btn>
          </div>
        </div>
      </div>
    </v-overlay>

    <v-card-actions>
      <v-btn v-if="showUpdateProductImage" color="#4390ce" text @click="showUpdateProductImage = false">
        Cancel
      </v-btn>
      <v-btn v-if="!showUpdateProductImage" color="#4390ce" text @click="emitViewPricing">
        View Pricing
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn v-if="!showUpdateProductImage" color="#4390ce" text @click="overlay = true">
        Add Pricing
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn v-if="!showUpdateProductImage" color="#4390ce" text @click="showUpdateProductImage = true">
        Update Image
      </v-btn>
      <v-btn v-if="showUpdateProductImage && !updatingProductImageLoader" color="#4390ce" text @click="updateProductImage">
        Upload New Image
      </v-btn>
      <v-progress-circular style='align-self: center;' v-if='showUpdateProductImage && updatingProductImageLoader' indeterminate :width="2" color="#283c86">
      </v-progress-circular>
    </v-card-actions>
  </v-card>
</div>
</template>

<script>
export default {
  name: 'Product',
  data() {
    return {
      overlay: false,
      showUpdateProductImage: false,
      updatingProductImageLoader: false,
      file: [],
      rules: [
        // eslint-disable-next-line arrow-parens
        value => !value || value.size < 3000000 || 'Image size should be less than 3 MB!',
      ],
    };
  },
  props: {
    productName: String,
    productType: String,
    activityDuration: Number,
    deliveryAddress: String,
    status: String,
    imgUrl: String,
    productId: Number,
  },
  computed: {
    productTypeClean() {
      return this.productType.split(';').join(', ');
    },
    token() {
      return this.$store.state.token;
    },
  },
  methods: {
    addNewPricing() {
      this.$router.push(`/add-pricing/${this.productName}/${this.productId}/${this.imgUrl.split('/')[6]}/${this.imgUrl.split('/')[7]}`);
    },
    emitViewPricing() {
      this.$emit('child-data', this.productId, this.productName);
    },
    emitViewPricingDuplicate() {
      this.overlay = false;
      this.$emit('child-data-duplicate', this.productId, this.productName);
    },
    async updateProductImage() {
      try {
        this.updatingProductImageLoader = true;
        const formData = new FormData();
        formData.append('productId', this.productId);
        formData.append('file', this.file);
        const url = `${process.env.VUE_APP_API_URL}/api/suppliers-old/update-product-image`;
        const response = await fetch(url, {
          method: 'PATCH',
          headers: { Authorization: `Bearer ${this.token}` },
          body: formData,
        });
        this.updatingProductImageLoader = false;
        this.showUpdateProductImage = false;
        this.file = [];
        if (response.status !== 200) return this.$store.commit('notify', 'Error updating product image. Make sure it does not exceed 3MB.');
        const jsonRes = await response.json();
        this.imgUrl = jsonRes;
        return console.log(jsonRes);
      } catch (err) {
        return this.$store.commit('notify', 'Error updating product image. Make sure it does not exceed 3MB.');
      }
    },
  },
};
</script>
